import React from 'react';
// import ReactDOM from 'react-dom/client';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { HashRouter as Router } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";




// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <Router>
//       <App/>
//     </Router>
    
// );
// reportWebVitals();

ReactDOM.render(
  <Router>
    <App />
  </Router>
  , document.getElementById('root')
);