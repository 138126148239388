// import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Routes, Route} from "react-router-dom";
import About from "./routes/About";
import Home from "./routes/Home";
import Edition from "./routes/Edition";
import House from "./routes/House";
import Contribute from "./routes/Contribute";
import Testimonies from "./routes/Testimonies";
import Beliefs from "./routes/Beliefs";
import Family from "./routes/Family";
import Withness from "./routes/Withness";
import Navbar from './Navbar';
// import NavbarBottom from './NavbarBottom';


function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/edition" element={<Edition />} />
        <Route path="/house" element={<House />} />
        <Route path="/contribute" element={<Contribute />} />
        <Route path="/testimonies" element={<Testimonies />} />
        <Route path="/beliefs" element={<Beliefs />} />
        <Route path="/family" element={<Family />} />
        <Route path="/withness" element={<Withness />} />
      </Routes>
      {/* <NavbarBottom />  */}
    </>
  );
}

export default App;