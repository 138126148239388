import React from 'react';


const Withness =()=>{
      return (
            <div>
                  <div class="overall">
                        <div class="intro">
                              <h1 class="title">Le témoin 5.3</h1>
                        </div>
                        <h2 class="description-Other">
                              La vie chrétienne est une vie intentionnelle et engagée. On ne peut pas devenir un témoin
                              puissant du Seigneur sans s’engager. Le Seigneur nous demande de le suivre afin de devenir
                              ses témoins.
                        </h2>
                        <div class="description_about white-section">
                              <img alt="temoin_communautaire" id="full-lenght-generated-text" class="picture-other" src={require('./image/temoin_5_3.png')} />
                        </div>
                        <div id="special_tri_div" class="tri-div-other">
                              <div class="tri-div-other-1-2">
                                    <div><i class="bi bi-house-fill"></i></div>
                                    <h3>Culte à la maison</h3>
                              </div>
                              <div class="tri-div-other-1-2 second-accent-color">
                                    <div><i class="bi bi-house-heart"></i></div>
                                    <h3>Culte en assemblée</h3>
                              </div>
                              <div class="tri-div-other-3">
                                    <div><i class="bi bi-person-walking"></i></div>
                                    <h3>Jumelage de relation</h3>
                              </div>
                        </div>
                        <div class="double-div-other white-section">
                              <div class="double-div-other-1">
                                    <div><i class="bi bi-book-fill"></i></div>
                                    <h3>Prière collective</h3>
                              </div>
                              <div class="double-div-other-2">
                                    <div><i class="bi bi-book"></i></div>
                                    <h3>Étude biblique</h3>
                              </div>
                        </div>
                        <div class="spacing"></div>
                        <div class="white-section">
                              <h2 class="subtitles-others">Il participe également à trois activités personnelles.</h2>
                        </div>
                        <div id="activite-perso" class="tri-div-other">
                              <div class="tri-div-other-1-2">
                                    <img alt="Prayer_Bible" class="withness-tri-div-picture" src={require('./image/prayer_with_bible.jpg')} />
                                    <h3 class="withness-tri-div-text">Prière personnelle</h3>
                              </div>
                              <div class="tri-div-other-1-2 second-accent-color">
                                    <img alt="Read_Bible" class="withness-tri-div-picture" src={require('./image/read_bible.jpg')} />
                                    <h3 class="withness-tri-div-text">Lecture et méditation de la Bible</h3>
                              </div>
                              <div class="tri-div-other-3">
                              <img alt="Appel" class="withness-tri-div-picture" src={require('./image/appel.jpg')} />
                              <h3 class="withness-tri-div-text">Répondre à son appel</h3>
                              </div>
                        </div>
                        <div id="end-box-withness" class="double-div-other second-accent-section">
                              <div class="double-div-other-1">
                                    <img alt="Prayer with Bible" class="picture-other" src={require('./image/people_praying_with_Bible.jpg')} />
                              </div>
                              <div id="withness-end-sentence" class="double-div-other-2">
                                    <h2 id="main-description-other-special" class="main-description-other">
                                          Ceux qui sont engagés à ces activités deviendront des témoins puissants du
                                          Seigneur dans le temps fixé par le Seigneur lui-même.</h2>
                              </div>
                        </div>
                  </div>
            </div>
      )
}
export default Withness;
