import React from 'react';


const Edition =()=>{
      return (
            <div>
                  <div class="overall">
                        <div class="intro">
                              <h1 class="title">Testimony Edition</h1>
                        </div>
                        <div class="description">
                              <em>Testimony Edition</em> est une maison d'édition fesant partie de la <em>Mission Testimony</em> qui
                              a pour but de partager des témoignages de personnes ordinaires à travers des livres.
                        </div>
                        <div class="content">
                              <div class="subtitles">Librarie</div>
                              <div id="book_section" class="double-div-section" >
                                    <div class="double-div-section-1">
                                          <img alt="mountain" id="annoucement_image" src={require('./image/4k-mountain.jpg')} />
                                    </div>
                                    <div class="double-div-section-2">
                                          <div id="book_description">
                                                <p><em>The Meaning of 53</em> by <bold>Louis Armand Paulin</bold></p>
                                                <p>It turns out that number 53 has a lot of occurrences in the Bible. 
                                                Isaiah 53 refers to Jesus as the perfect servant. The word perfect is 
                                                used fifty-three times in the Bible. The word pastor is used fifty-three 
                                                times in the Old Testament and fifty-three times in the New Testament. 
                                                The word I Am is used fifty-three times in the Gospel of John. 
                                                </p>
                                                <p class="text_overflow">And finally, 
                                                the word rescuer is used fifty-three times in the Bible. These four 
                                                characters of Jesus are presented in the four gospels. Jesus is the I 
                                                Am (God), the pastor, the perfect servant, and the rescuer. The number 53 is an indivisible number. The 5 is the human number. 
                                                And the 3 is God’s number reunited in the Trinity. The number 53 means 
                                                that as human beings, we are indivisible with God. In the entire creation, 
                                                only the human being has the option of redemption through Jesus Christ. 
                                                And since the number 5 precedes the number 3, everything we want from God 
                                                starts with us. Louis Armand’s life has been changed forever. His goal is 
                                                to go around the world to spread the message: we are indivisible with God.</p>  
                                                <p class="text_overflow_message">Expand the page to see more.</p>    
                                          </div>
                                    </div>
                              </div>
                              <div class="request_E">
                                    <div class="request_question_E">Voulez-vous publier un livre?</div>
                                    <div class="middle_button_E"><a class="button_E" href="https://docs.google.com/forms/d/e/1FAIpQLSfXO_didhykUQltQeDN-R4iZ2EfDydzDLDDvjK2wfb-pwWGeg/viewform?usp=pp_url">
                                          Envoyez nous une requête en remplissant ce formulaire.
                                    </a></div>
                              </div>
                        </div>
                  </div>
            </div>
            
            )
}
export default Edition;

