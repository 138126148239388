import React from 'react';

const Family =()=>{
      return (
            <div>
                  <div class="overall">
                        <div class="intro">
                              <h1 class="title">La famille</h1>
                        </div>
                        <h2 class="description-Other">
                              La famille est au cœur de nos priorités. Nous savons que Dieu a un plan de bonheur pour les
                              familles et qu’il veut les bénir pour sa gloire.
                        </h2>
                        <div class="double-div-other white-section">
                              <div class="double-div-other-1">
                                    <img alt="fathers" class="picture-other" src={require('./image/father_1.jpg')} />
                              </div>
                              <div id="ajustment" class="double-div-other-2">
                                    <h2 class="main-description-other">Nous aidons les pères à devenir des hommes intègres qui travaillent pour le bonheur de leur
                                    famille. </h2>
                              </div>
                        </div>
                        <h3 id="family-text-color" class="description_about white-section">
                        Nous les aidons à aimer leur femme et élever leurs enfants selon le Seigneur. Nous les
                        aidons à défendre leur famille. Nous les aidons à servir le Seigneur, à être forts et courageux.
                        Nous aidons les pères à travailler pour laisser un bon héritage à leurs enfants. Et finalement, nous
                        les aidons à vivre par le caractère de Jésus-Christ et à s’éloigner de l’orgueil.
                        </h3>
                        <div class="double-div-other white-section">
                              <div id="women-description"  class="double-div-other-1">
                                    <h2 class="main-description-other">Nous aidons les femmes à devenir des femmes fidèles et loyales qui travaillent pour le bonheur de leur
                                    famille.</h2>
                              </div>
                              <div class="double-div-other-2">
                                    <img alt="mothers" class="picture-other" src={require('./image/mothers_1.jpg')} />
                              </div>
                        </div>
                        <h3 id="family-text-color" class="description_about white-section">
                              Nous les aidons à prendre soin d’elles physiquement et mentalement. Nous les aidons à
                              être des femmes vertueuses qui vivent dans la simplicité et l’humilité. Et finalement, nous
                              aidons les femmes à vivre par le caractère de Jésus-Christ et à s’éloigner de la médisance.
                        </h3>
                        <div class="double-div-other accent-section">
                              <div class="double-div-other-1">
                                    <img alt="children" class="picture-other" src={require('./image/kids_with_adults.jpg')} />
                              </div>
                              <div id="ajustment" class="double-div-other-2">
                                    <h2 class="main-description-other">Nous aidons les enfants et les jeunes à apprendre 
                                          des expériences de leurs parents afin de faire mieux.</h2>
                              </div>
                        </div>
                        <h3 id="family-text-color" class="description_about accent-section">
                              Nous les aidons à obéir aux parents selon le Seigneur et à vivre dans la pureté. Nous les aidons
                              à poursuive et à atteindre le rêve que Dieu a mis en eux. Et finalement, nous les aidons à suivre
                              Jésus-Christ et à s’éloigner de la rébellion.
                        </h3>
                  </div>
            </div>
      )
}
export default Family;
