import React  from 'react';
import Carousel from 'react-bootstrap/Carousel';



const Home =()=>{

    return (
        <div>
            <Carousel data-bs-theme="light">
                <Carousel.Item>
                    <img 
                    className="d-block w-100"
                    src={require('./image/bienvenue_Mission.jpg')}
                    alt="Soirée Témoignage de 2018"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require('./image/event_1.JPG')}
                    alt="Soirée Témoignage de 2018"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require('./image/event_4.JPG')}
                    alt="Soirée Témoignage de 2018"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require('./image/event_6.JPG')}
                    alt="Soirée Témoignage de 2018"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require('./image/prayer_testimony.JPG')}
                    alt="Soirée Témoignage de 2018"
                    />
                </Carousel.Item>
            </Carousel>
            <div class="white-section">
                <div class="mission-description">
                    Nous vivons par l’amour. Et dans l’amour, il y a trois aspects fondamentaux:
                </div>
            </div>
            <div class="tri-div-other">
                <div class="tri-div-other-1-2">
                    <div class="tri-div-title">La compassion</div>
                    <div class="tri-div-text">Nous étions perdus et détruits. Jésus-Christ a eu compassion de nous. Il
                    nous a délivrés et guéris. En retour, il nous demande d’avoir la compassion envers ceux qui ne le
                    connaissent pas. </div>
                </div>
                <div id="emphasis-text" class="tri-div-other-1-2">
                    <div class="tri-div-title">La souffrance</div>
                    <div class="tri-div-text">
                    Jésus-Christ a souffert pour nous à la croix. Par sa souffrance, nous avons reçu
                    la vie abondante en lui. Aujourd’hui, il nous demande de souffrir avec ceux qui souffrent et qui
                    ne le connaissent pas.
                    </div>
                </div>
                <div class="tri-div-other-3">
                    <div class="tri-div-title">La vérité</div>
                    <div class="tri-div-text">
                    Jésus-Christ nous a révélé la vérité sur nous-mêmes. Et il nous a révélé qui nous
                    sommes avec lui et sans lui. Nous sommes libérés. Aujourd’hui, par le Saint-Esprit qui vit en
                    nous, il nous demande de témoigner la vérité à ceux qui ne le connaissent pas.
                    </div>
                </div>
            </div>
            <div class="white-section">
                <h1 class="story-title">Évènements</h1>
            </div>

            <h2 class="description_about white-section">
            En plus de nos activités communautaires, nous avons des évènements 
            pour le corps du Christ et pour le grand public en général. Nous invitons 
            régulièrement des hommes et femmes de Dieu à venir prêcher pour nous. 
            C’est une occasion favorable de bâtir de fortes relations entre nous; 
            frères et sœurs en Christ.
            </h2>
            <h2 class="description_about white-section">                
                Voici quelques évènements et dates :
            </h2>
            <h3 class="description_about white-section">                
                Mars-Avril : Célébration de la Pâques
            </h3>
            <h3 class="description_about white-section">                
                Mai-Juin-Juillet : Évangélisation sur le terrain
            </h3>
            <h3 class="description_about white-section">                
                Juin : Fin de semaine de gloire
            </h3>
            <h3 class="description_about white-section">                
                Juillet : Grande soirée de témoignage
            </h3>
            <h3 class="description_about white-section">                
                Août : Picnic dans un parc. Collecte de dons, habits et denrées pour des enfants en Haïti
            </h3>
            <h3 class="description_about white-section">                
                Septembre : Rentrée Josué
            </h3>
            <h3 class="description_about white-section">                
                Décembre : Grande soirée témoignage. Soirée d’adoration. Soirée Agapè
            </h3>
            <h3 class="description_about white-section">                
                Suivez nos annonces sur les réseaux sociaux pour connaitre les dates exactes.
            </h3>
            <div class="spacing"></div>
        </div>
    )
}
export default Home;

