import React from 'react';
import { Link } from "react-router-dom";
// import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';


function Navbartop() {
  return (
    <>
        <Navbar key='sm' expand='sm' className="navbar-expand-lg navbar-dark shadow-5-strong" collapseOnSelect>
          <Container fluid>
            <Navbar.Brand href="#">Mission Témoignage | Mission Testimony</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-sm`}
              aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body id="off-canva">
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link as={Link} to="/" eventKey="1">Accueil</Nav.Link>
                  <Nav.Link as={Link} to="/about" eventKey="2">Notre mission</Nav.Link>
                  <Nav.Link as={Link} to="/beliefs" eventKey="3">Nos croyances</Nav.Link>
                  <Nav.Link as={Link} to="/withness" eventKey="4">Le témoin 5.3</Nav.Link>
                  <Nav.Link as={Link} to="/family" eventKey="5">La famille</Nav.Link>
                  <Nav.Link as={Link} to="/testimonies" eventKey="6">Profils et témoignages</Nav.Link>
                  <Nav.Link as={Link} to="/contribute" eventKey="7">Contact et don</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
    </>
  );
}
export default Navbartop;



// <NavDropdown
// title="Testimony Edition"
// id={`offcanvasNavbarDropdown-expand-sm`}
// >
// <NavDropdown.Item as={Link} to="/edition">Qu'est-ce que Testimony Edition?</NavDropdown.Item>
// <NavDropdown.Item as={Link} to="/edition">Librarie</NavDropdown.Item>
// <NavDropdown.Item as={Link} to="/edition">Publie un livre</NavDropdown.Item>
// </NavDropdown>
// <NavDropdown
// title="Maison Testimony"
// id={`offcanvasNavbarDropdown-expand-sm`}
// >
// <NavDropdown.Item as={Link} to="/house">Mission</NavDropdown.Item>
// <NavDropdown.Item as={Link} to="/house">Contruire ta maison</NavDropdown.Item>                  
// </NavDropdown>