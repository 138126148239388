import React  from 'react';


const About =()=>{
      return (
            <div>
                  <div class="overall">
                        <div class="intro">
                              <h1 class="title">Notre Mission</h1>
                        </div>
                        <div class="white-section">
                              <h2 class="subtitles-others">Notre mission a deux mandats :</h2>
                        </div>  
                        <div class="section-other white-section">
                              <img id="test" alt="event_2_image" class="picture-other" src={require('./image/event_2.JPG')} />
                              <h4 class="image-description">Première soirée témoignage</h4>
                              <h4 class="image-description">16 Décembre 2017</h4>

                        </div> 
                        <div class="section-other white-section">
                              <h1 class="tri-div-title">
                                    1- Élever des témoins puissants pour la 
                                    gloire de notre Seigneur Jésus-Christ <em>(Actes 1:8)</em>
                              </h1> 
                              <h3 class="tri-div-text">
                                    Nous voyons la manifestation de la peur et de l’iniquité dans le monde. Nous savons que nous
                                    sommes à la fin des temps. Dieu veut manifester sa puissance et son amour à travers des
                                    témoins de Jésus-Christ. Ceux-ci sont des gens ordinaires qui décident de vivre selon la volonté
                                    du Seigneur, et qui sont dirigés par le Saint-Esprit de Dieu.
                              </h3>    
                        </div> 
                        <div class="section-other white-section">
                              <h1 class="tri-div-title">
                                    2- Bâtir des familles puissantes dans le royaume de Dieu <em>(Ézéchiel 28:26).</em>
                              </h1> 
                              <h3 class="tri-div-text">
                                    La famille a toujours été au centre des priorités de Dieu. Malgré la chute de la première famille
                                    au jardin d’Eden, Dieu continue de bénir les familles. Mais en même temps, l’ennemi continue
                                    de s’acharner sur celles-ci. Les souffrances dans les familles sont entièrement l’œuvre de Satan
                                    et ses démons. Les pères et/ou les mères de familles devraient se lever non-seulement pour se
                                    défendre mais aussi pour bâtir leur vie.
                              </h3>    
                        </div> 
                        <div class="white-section">
                              <h1 class="story-title">Notre Histoire</h1>
                        </div>

                        <h2 class="description_about white-section">
                              Après avoir reçu la révélation de Jésus-Christ, le 7 septembre 2014, Louis Armand ne savait
                              pas ce qu’était sa mission. Il était en prière et servait à l’église du Seigneur. Et il partageait son
                              témoignage sur les médias sociaux. Tout au long de la Bible, Dieu a utilisé des images pour assigner 
                              des missions à des prophètes. Il a utilisé l’image d’une amande pour confier une mission au prophète Jérémie.
                        </h2>
                        <h2 class="description_about white-section">
                              Un matin de mars 2017, le Seigneur a envoyé l’image d’un œil dans les yeux de Louis Armand.
                              S'inspirant du Saint-Esprit et des prières, Louis Armand a compris que l'œil représente le
                              témoignage : L'œil regarde et témoigne ce qu’il voit. Il a compris également que les yeux de
                              Dieu étaient sur lui, il devrait garder les siens sur le Seigneur. En gardant les yeux sur Jésus-
                              Christ, nous pouvons être ses témoins.
                        </h2>
                        <div class="double-div-other white-section">
                              <div class="double-div-other-1">
                                    <img alt="reunion_premiere_soiree" class="ajustement picture-other" src={require('./image/reunion_premier_groupe.JPG')} />
                                    <h4 class="image-description">Une des premières réunions de la Mission</h4>
                              </div>
                              <div id="withness-end-sentence" class="double-div-other-2">
                                    <h2 class="tri-div-text">
                                          C’est ainsi qu’il a répondu à l’appel du Seigneur Jésus-Christ et a fondé un groupe appelé «
                                          Témoignage ». Le 21 juillet 2017, Louis Armand et son épouse Magda ont animé leur première
                                          soirée de témoignage avec cinq autres personnes. Cette réunion a duré six heures. Puis, la Mission Témoignage est née.
                                    </h2>
                              </div>
                        </div>
                        <h2 class="description_about white-section">
                              La Mission Témoignage est une mission apostolique. Nous sommes oints par le Seigneur
                              Jésus-Christ lui-même pour construire des maisons et élever des témoins puissants à cette
                              période spécifique de la vie.
                        </h2>


                  </div>
            </div>
      )
}
export default About;