import React  from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


const testimonies =()=>{
  
    return (
        <div>
            <div class="overall">
                <div class="intro">
                    <h1 class="title">Témoignages</h1>
                </div> 
                <h2 class="description_testimonies">
                        La <em>Mission Témoignage</em> est axée sur le témoignage. Lisez les témoignages édifiants des 
                        oeuvres du Seigneur dans la vie de nos frères et soeurs.
                </h2>
                <div class="double-div-testimonies white-section">
                    <div class="double-div-testimonies-1">
                        <img alt="Photo_louis" class="picture-withness" src={require('./image/Photo_Apotre_Louis.jpg')} />
                        <h4 class="image-description">Apôtre Louis Armand Paulin</h4>
                        <h4 class="image-description">Leader, <em>Mission Témoignage</em> </h4>
                    </div>
                    <div class="double-div-testimonies-2">
                        <h2 class="title-testimony">L'amour de Dieu m'a poursuivi et m'a trouvé...</h2>
                        <h3 class="first-part-testimony">
                            Louis Armand est le troisième d'une famille de trois enfants. Il a grandi dans une famille chrétienne
                            en Haïti. Mais celle-ci était brisée. 
                        </h3>
                    </div>
                </div>
                <div class="extented-testimonies white-section">
                    <h3>Ses parents se sont séparés quand il avait trois ou quatre ans. Il a
                            demandé d’aller vivre avec sa mère alors que son frère et sa sœur sont restés avec mon père. Sa
                            mère l'amenait à l'église tous les dimanches. Il avait environ cinq ou six ans lorsqu’il a mis ma foi au
                            Seigneur Jésus-Christ. Très jeune, il a reçu un verset biblique qu’il garde avec lui jusqu’à ce jour :
                            <em> Jérémie 29: 11</em>. Dieu avait vraiment un plan de bonheur pour sa vie.
                    </h3>
                    <div class="spacing"></div>
                    <h3>
                        À l’âge de 17 ans, Louis Armand voulait réussir ses examens officiels en Haïti. Il se mettait à étudier
                        et prier intensément. Un jour dans une vision ou un songe, il a senti une forte présence autour de
                        lui. C’était comme si le soleil était tout près de lui. Il ne pouvait pas tourner sa tête pour regarder
                        tellement la lumière était intense. Le Seigneur l'a béni. Il a réussi les deux examens officiels. Il est
                        admis à l’Université d’État. Il a été instruit. Il est un ingénieur civil de la faculté des Sciences, en
                        Haïti. Et il a obtenu une double maîtrise en économie financière et en économétrie à l’Université de
                        Montréal, au Canada.
                    </h3>
                    <div class="spacing"></div>
                    <h3>
                        À l’âge de 19 ans, une voisine qui avait le sida voulait avoir une relation avec lui. Mais dans un rêve,
                        le Seigneur lui a prévenu du danger de s’approcher d’elle. Par la suite, elle est morte. Le mari de
                        cette femme, son voisin ainsi d’autres hommes avec qui elle avaient des relations sont morts du sida.
                        Sans la protection de Dieu, Louis Armand serait mort aussi.
                    </h3>
                    <div class="spacing"></div>
                    <h3>
                        À l’âge de 24 ans, Louis Armand est tombé dans le péché. Il n’a pas été fidèle au Seigneur. Son cœur
                        lui a fait défaut. Il n’avait pas perdu la foi en Dieu. Mais il était un pécheur dans l’assemblée des
                        saints. Il a eu une oppression spirituelle due à une dépendance affective. Mais chaque fois qu’il se
                        mettait à genoux devant le Seigneur, il le pardonnait, et il lui manifestait son amour. L'amour de Dieu
                        est plus grand que nos faiblesses.
                    </h3>
                    <div class="spacing"></div>
                    <h3>
                        L'amour de Dieu l'a poursuivi même s’il était aveugle et ne pouvait pas voir. Cet amour ne l'a jamais 
                        abandonné. En 2013, il a décidé de changer. Il a décidé d'être fidèle au Seigneur et de vivre selon sa 
                        volonté. Puis, le 7 septembre 2014, Dieu s'est révélé à lui. Il lui a montré comment Il l’a toujours protégé et béni. 
                        Le Seigneur s’est révélé à lui par une lumière qui a aveuglé ses yeux pendant quelques secondes lors 
                        d’une séance de prière. Étrangement, cette lumière était déjà en lui. Plus de 20 ans plus tard, le Seigneur 
                        s’est manifesté dans sa vie de façon tangible une seconde fois. Cette fois, il a eu une révélation de Jésus-Christ 
                        à travers le nombre 53, nombre qu’il a gardé avec lui pendant plus de 20 ans sans savoir pourquoi.
                    </h3>
                    <div class="spacing"></div>
                    <h3>
                        Le Seigneur l’a délivré de l’oppression spirituelle, l’a guéri de ses blessures d’enfance et l’a appelé 
                        pour Son œuvre. Il a été tellement touché par l’amour de Dieu qu’il a décidé de lui donner le reste de sa 
                        vie et son éternité, car cet amour est inconditionnel. Jésus-Christ nous aime vraiment tous. 
                        Louis Armand est ici pour témoigner de cet amour au monde entier.
                    </h3>
                    <div class="spacing"></div>
                    <h3>
                    Louis Armand est appelé à être un témoin de Jésus-Christ dans le monde. Il est également appelé à aimer 
                    ceux qui adorent de faux dieux. Louis Armand est né dans l'Église du Seigneur. Il a servi à l'Église et 
                    a été formé par des hommes et des femmes de Dieu. Cependant, son intimité avec le Saint-Esprit est la 
                    principale source de ses prédications.
                    </h3>
                    <div class="spacing"></div>
                    <h3>
                    En décembre 2019, il a reçu deux clés des mains du Seigneur pour être pasteur et apôtre dans Son Église. 
                    Plusieurs frères et sœurs reconnaissent son appel apostolique. Louis Armand travaille depuis presque vingt 
                    ans dans le domaine financier au Canada. Il est auteur et éditeur de livres chrétiens. Il est marié et a 
                    trois enfants. Louis Armand vit au Canada depuis 2001.
                    </h3>
                    <div class="spacing"></div>
                    <h3>
                        Louis Amand est l’Apôtre du Seigneur pour la Mission Témoignage.
                    </h3>
                    <div class="spacing"></div>
                </div>
                <div class="double-div-testimonies white-section">
                    <div class="double-div-testimonies-1">
                        <img alt="Photo_Magda" class="picture-withness" src={require('./image/Photo_Pasteure_Magda.jpg')} />
                        <h4 class="name-withness">Pasteure Magda JN Baptiste</h4>
                        <h4 class="title-withness">Pasteure, <em>Mission Témoignage</em> depuis mars 2023 </h4>
                    </div>
                    <div class="double-div-testimonies-2">
                        <h2 class="title-testimony">Une femme transformée</h2>
                        <h3 class="first-part-testimony">
                        Enfant née d’une union adultérine, sa mère lui a appris à prier Dieu. Elle se rappelle 
                        qu'à l’âge de 6 ou 7 ans, en revenant de l’école, elle se couchait par terre et cherchait 
                        le visage de Dieu à travers les nuages.
                        </h3>
                    </div>
                </div>
                <div class="extented-testimonies white-section">
                    <h3>
                        Un jour, à l’approche de Noël, alors qu’elle revenait d’une répétition en prévision du concert de Noël 
                        à l’église où elle allait à cette époque, elle se trouvait seule sur le trottoir lorsqu’une camionnette 
                        s’est dirigée vers elle. Elle a juste eu le temps de courir dans une forêt. La camionnette l’a poursuivie 
                        jusqu’au fond. À un moment donné, la camionnette a reculé et est repartie. Le chauffeur aurait pu descendre 
                        et l’attraper. Au fil du temps, à travers d’autres drames dans sa vie, elle a compris que la main protectrice 
                        du Dieu Tout-Puissant était avec elle. Elle avait environ 8 ans quand elle a failli se faire enlever.
                    </h3>
                    <div class="spacing"></div>
                    <h3>
                        À l’âge de 11 ans, cette même main protectrice est venue à son secours alors qu’elle allait se
                        noyer dans une rivière. Elle s’enfonçait dans un trou très profonde, son corps était
                        complètement submergé. Elle se voyait entrain de mourir quand une force est entrée en elle et
                        l’a poussée vers le haut, l’a sortie du trou, elle a craché du sang.
                    </h3>
                    <div class="spacing"></div>
                    <h3>
                        À 24 ans, à la suite d’une grande déception, alors qu’elle était sur le point de se suicider, Dieu
                        lui a dit de ne pas se faire du mal, qu’il est au contrôle de sa situation. Elle n’était pas passée à
                        l’acte et Dieu a changé ce drame en quelque chose de beau pour sa gloire. Il a enlevé sa honte.
                    </h3>
                    <div class="spacing"></div>
                    <h3>
                        En 2006, alors qu’elle roulait à moto, elle a eu une collision frontale avec une camionnette, Le
                        choc l’a projetée sur le pare-brise et la moto était sous la camionnette. Elle sentait son esprit qui
                        quittait son corps, elle se voyait mourir. Elle n’avait même pas eu une égratignure. Encore cette
                        main protectrice, la main de Dieu qui l’a secourue.
                    </h3>
                    <div class="spacing"></div>
                    <h3>
                        En plus d'un héritage ancestral très lourd, elle a fait des erreurs dans sa vie à cause du péché. 
                        Ce qui a fait qu'elle a connu beaucoup de situations dramatiques qui pourraient détruire complètement sa vie. 
                        Elle a beaucoup jeuné et prié afin d'obtenir la grâce et la faveur de Dieu. 
                        Elle a mené de rudes combats par la foi et la repentance. Aujourd'hui, elle peut témoigner 
                        l'amour, la miséricorde et la grâce sur abondante de Dieu ainsi que la puissance du sang de Jésus.
                    </h3>
                    <div class="spacing"></div>
                    <h3>
                        Au fil du temps, elle a compris que Dieu a été toujours avec elle et ce souffle de vie qu’il a mis
                        en elle est d’abord pour sa gloire. Elle a connu beaucoup de souffrances, son cœur était brisé. Dieu l’a restaurée pour sa gloire.
                        Et aujourd’hui, elle peut comprendre la souffrance de l’autre et Dieu l’a outillée pour soutenir
                        toutes les âmes abattues qu’elle côtoie dans son quotidien afin qu’elles obtiennent à leur tour la
                        délivrance, la guérison et la restauration en Jésus-Christ.
                    </h3>
                    <div class="spacing"></div>
                    <h3>
                        Elle est infirmière de profession depuis 1997. En Haïti, elle a été membre, vice-présidente et
                        présidente de groupe de jeunes à l’église où elle marchait. Elle vit au Canada depuis 2006 et
                        continue d’exercer la profession d’infirmière. Avant de répondre à la mission Témoignage, elle
                        était bénévole à l’accueil au ministère des enfants et au service de premiers soins. Elle assistait
                        aussi, son mari Louis Armand dans sa fonction de leader de petit-groupe de l’église.
                    </h3>
                    <div class="spacing"></div>
                </div>
            </div>
        </div>
    )
}
export default testimonies;