import React from 'react';

const Contribute =()=>{
      return (
            <div>
                  <div class="overall">
                        <div class="intro">
                              <h1 class="title">Contact et Don</h1>
                        </div>  
                        <div class="double-div-contact white-section">
                              <div class="double-div-contact-1"><img alt="centre_armenie" id="full-width-image" src={require('./image/centrearmenie.jpg')} />
                              </div>
                              <div class="double-div-contact-2">
                                    <div><i class="bi bi-geo-alt icon-style"></i></div>
                                    <h2>Centre Arménie</h2>
                                    <h2>1025, Boulevard Elisabeth</h2>
                                    <h2>Laval, QC H7W 3J7</h2>
                                    <h5 class="important-info">1er et 3ème dimanches du mois à 16h </h5>
                              </div>
                        </div>                      
                        <div class="double-div-contact white-section">
                              <div class="double-div-contact-1">
                                    <div><i class="bi bi-envelope-at icon-style"></i></div>
                                    <h4>Adresse courriel</h4>
                                    <h4>missiontestimony@gmail.com</h4>
                              </div>
                              <div class="double-div-contact-2">
                                    <div><i class="bi bi-telephone icon-style"></i></div>
                                    <h4>Téléphone (sans frais)</h4>
                                    <h4>1 866 661 0266</h4>
                              </div>
                        </div>
                        <div class="paypal-info white-section">
                                    <div><i class="bi bi-paypal icon-style"></i></div>
                                    <h4>Pour faire un don</h4>
                                    <h4>Paypal.me/testimony53</h4>
                                    <h4>ou par courriel à </h4>
                                    <h4>missiontestimony@gmail.com</h4>
                              </div>
                              <div class="spacing"></div>
                        <h2 id="rejoindre" class="subtitles-contact white-section">Rejoignez-nous sur les réseaux sociaux.</h2>
                        <div class="double-div-contact white-section">
                              <div class="double-div-contact-1">
                                    <div><i class="bi bi-youtube icon-style"></i></div>
                                    <h3>@testimony53</h3>
                                    <h3><a id="link-color" href='https://www.youtube.com/@testimony53'>Aller sur la chaîne Youtube</a></h3>
                              </div>
                              <div class="double-div-contact-2">
                                    <div ><i class="bi bi-facebook icon-style"></i></div>
                                    <h3>Mission Témoignage/ Testimony</h3>
                                    <h3><a id="link-color" href='https://www.facebook.com/MissionTestimony'>Aller sur la page Facebook</a></h3>
                              </div>
                        </div> 
                  </div>  
            </div>
      )
}
export default Contribute;
