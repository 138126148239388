import React from 'react';

const Beliefs =()=>{
      return (
            <div>
                  <div class="overall">
                        <div class="intro">
                              <h1 class="title">Nos croyances</h1>
                        </div>
                        <h2 class="description-Other">
                              Nous croyons qu’il y a un seul vrai Dieu qui est non-créé et Éternel <em>(Esaïe 44:6; Esaïe 45:5-6)</em>. 
                              Nous croyons qu’il a toujours existé en trois personnes : Le Père, le Fils (Jésus-Christ) et le
                              Saint-Esprit <em>(Mathieu 3:16-17; Mathieu 28:19)</em>.
                        </h2>
                        <div class="section-other white-section">
                              <img alt="prayer_testimony" class="picture-other" src={require('./image/prayer_testimony.JPG')} />
                        </div>
                        <h2  class="description_about white-section">Nous croyons dans la puissance du Saint-Esprit pour guérir les malades, chasser des démons
                        et opérer des miracles au nom de Jésus-Christ. <em>(Luc 9:1-2; Luc 10:19)</em></h2>
                        <div id="test" class="main-belief-text-block white-section">
                              <h2 id="belief-text-block">
                              Par le sacrifice de Jésus-Christ à la croix, nous sommes réconciliés avec Dieu en vivant une vie de repentance <em> (2 Corinthiens 5:18- 21)</em>. En Jésus-Christ, nous avons la victoire sur le péché, 
                              sur la mort et sur Satan et ses démons. <em>(Apocalypse 12:11, Romains 6:14; 1 Corinthiens 15:55-57)</em>.
                              La Bible est la parole de Dieu et le Saint-Esprit en est l’auteur <em>(2 Timothée 3:16-17)</em>.
                              </h2>
                        </div>
                        <div class="white-section">
                              <h1 class="subtitles-others">Ce que nous enseignons.</h1>
                        </div>    
                        <h2 class="description_about white-section">
                              La Bible est christocentrique. Dieu se révèle et révèle les projets de son cœur en Jésus-Christ.
                              L’enseignement à la mission témoignage est très christocentrique. Nous prêchons Jésus-Christ
                              par le Saint-Esprit qui vit en nous pour glorifier le Père. Plus précisément, nous prêchons trois aspects fondamentaux de la vie chrétienne:
                        </h2>
                        <div id="belief-tri-div" class="tri-div-other">
                              <div class="tri-div-other-1-2">
                                    <h2 class="tri-div-title">La repentance</h2>
                                    <h3 class="tri-div-text">Sans vivre une vie de repentance à Jésus-Christ, il est impossible d’entrer au royaume de Dieu
                                    et d’y demeurer. <em>(Mathieu 3 :2)</em></h3>
                              </div>
                              <div id="emphasis-text" class="tri-div-other-1-2">
                                    <h2 class="tri-div-title">La vie abondante</h2>
                                    <h3 class="tri-div-text">
                                          Jésus-Christ a promis la vie abondante à ses brebis <em>(Jean 10 :10)</em>. Nous pouvons vivre cette
                                          promesse en cherchant le Seigneur à tous les jours et en chassant les mauvais esprits qui
                                          veulent voler nos vies. Cette vie abondante se vit dans la présence du Seigneur à tous les
                                          jours.
                                    </h3>
                              </div>
                              <div class="tri-div-other-3">
                                    <h2 class="tri-div-title">Les relations fortes</h2>
                                    <h3 class="tri-div-text">
                                          La vie chrétienne est une vie communautaire qui se vit à travers une relation d’amour. Si
                                          nous vivons par l’amour, nous serons forts et puissants. Et nous serons des disciples de
                                          Jésus-Christ <em>(Jean 13 :35)</em>. Et le monde saura qu’il vient de Dieu.
                                    </h3>
                              </div>
                        </div>
                  </div>
            </div>
      )
}
export default Beliefs;
