import React from 'react';

const House =()=>{
      return (
            <div>
                  <div class="overall">
                        <div class="intro">
                              <h1 class="title">Maison Testimony</h1>
                        </div>
                        <div class="description">
                              Une <em>maison Testimony</em> est une branche de la <em>mission Testimony</em> qui soucie de 
                              construire des maisons de familles puissantes qui vont impacter leur entourage.
                        </div>
                        <div class="content">
                              <div id="request_H">
                                    <div id="request_question_H">Voulez-vous contruire une maison?</div>
                                    <div class="middle_button_H"><a class="button_H" href="https://docs.google.com/forms/d/e/1FAIpQLScqVbuChJ7P8vpZLfMP3RknpXxZyE_m8c4fXSuAC35JgP9TPA/viewform?usp=sf_link">
                                          Envoyez nous une requête en remplissant ce formulaire.
                                    </a></div>
                              </div>
                        </div>
                  </div>
            </div>
      )
}
export default House;
